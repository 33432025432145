import Entity, { Transformer } from '~/contracts/entity'
import { formatAmount } from '~/libs/currencies/utils'
import Entrepreneur from '~/modules/entrepreneurs/entities/frontend/entrepreneur'
import Team from '~/modules/teams/entities/frontend/team'
import Lender from '~/modules/users/entities/frontend/lender'
import BufferRepayment from './buffer-repayment'
import LoanImage from './loan-image'
import LoanUpdate from '~/modules/updates/entities/update'
import Repayment from './repayment'

export default class Loan extends Entity {
  _transformers () {
    return {
      activity: String,
      amount: Number,
      approvedAt: Transformer.date(),
      bufferRepayment: Transformer.item(BufferRepayment),
      createdAt: Transformer.date(),
      currency: String,
      description: String,
      disbursed: Transformer.date(),
      entrepreneur: Transformer.item(Entrepreneur),
      entrepreneurDescription: String,
      fundedAt: Transformer.date(),
      fundingAmount: Number,
      fundingAmountRemaining: Number,
      fundingCurrency: String,
      groupMembers: Number,
      id: Number,
      images: Transformer.collection(LoanImage),
      lenders: Transformer.collection(Lender),
      location: String,
      purpose: String,
      repayments: Transformer.collection(Repayment),
      reserved: Boolean,
      status: String,
      teams: Transformer.collection(Team),
      term: Number,
      updates: Transformer.collection(LoanUpdate),
    }
  }

  get formattedFundingAmount () {
    return formatAmount(this.fundingAmount, this.fundingCurrency, true)
  }

  get formattedFundingAmountRemaning () {
    return formatAmount(this.fundingAmountRemaining, this.fundingCurrency, true)
  }

  get fundingAmountFunded () {
    return this.fundingAmount - this.fundingAmountRemaining
  }

  get formattedFundingAmountFunded () {
    return formatAmount(this.fundingAmountFunded, this.fundingCurrency, true)
  }

  get mainImage () {
    const found = this.images.find(img => img.isMain === true)

    return found || this.images[0]
  }

  get secondaryImages () {
    return this.images.filter(img => !img.isMain)
  }

  get route () {
    return {
      name: 'loans:frontend:show',
      params: {
        loanId: this.id,
      },
    }
  }

  _apiMapping () {
    return {
      loanImages: 'images',
      loanRepayments: 'repayments',
      loanUpdates: 'updates',
    }
  }
}
