import { SET_CURRENT } from './mutation-types'
import Bus, { MFI_CONTEXT_UPDATED } from '~/bus'
import template from 'lodash/template'
import { apiRoutes } from '@mfis/config'
import { getInstance } from '~/plugins/http'
import { unwrapResponse } from '@/utils/framework'

/**
 * Commit a mutation setting a current MFI context and emit an event.
 *
 * @type {function}
 * @fires Bus#MFI_CONTEXT_UPDATED
 * @param {function} commit - Vuex commit function.
 * @param {(MFIs~MFI|null)} current - Current MFI context.
 */
export const setCurrentMfi = ({ commit }, current) => {
  commit(SET_CURRENT, current)
  Bus.$emit(MFI_CONTEXT_UPDATED, current)
}

/**
 * Fetch defined profile templates of a requested type (loan or grant) for the
 * current MFI.
 *
 * @param {function(mutation: string, mfi: MFI)} commit
 * @param {MFIStoreState} state
 * @param {MFIProfileTemplateType} type
 * @returns {Promise<MFI>}
 */
const fetchProfileTemplates = async function ({ state }, type) {
  const id = state.current.id
  const uri = template(apiRoutes.admin.profileTemplateOptions)({id, type})
  const res = await getInstance().get(uri)

  return res.data.data?.config?.profileTemplates || []
}

/**
 *
 * @param {MFIStoreState} state
 * @param {number} tplId
 * @returns {Promise<MFIProfileTemplate>}
 */
const fetchProfileTemplate = async function ({ state }, tplId) {
  const id = state.current.id
  const uri = template(apiRoutes.admin.profileTemplate)({id, tplId})
  const res = await getInstance().get(uri)

  return unwrapResponse(res.data)
}

export default {
  fetchProfileTemplates,
  fetchProfileTemplate,
  setCurrentMfi,
}
