import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80f9da1c = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _bececfe8 = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _79c980ca = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _fd920a44 = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _22fe6fb4 = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _0c6d9ec4 = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _7db6cbca = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _01b280ec = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _0d462c80 = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _7c6a6b9c = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _1b653e3c = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _44847200 = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _1090f3b5 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _330b9412 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _b62bb728 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _2a663b9a = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _55e07629 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _56a9c8d0 = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _272ca790 = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _4b962c95 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _adee9a2c = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _81cf3bee = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _19ef8ea5 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _507ab1f3 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _9f593a70 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _71b50259 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _51a23537 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _04903aa7 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _12eb2905 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _08839368 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _737e3643 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _509f840e = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _649e77bc = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _385760fc = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _5231a38b = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _384d94c0 = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _52b91058 = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _645ed3d0 = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _0b36ab94 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _2bf37dc4 = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _3222e022 = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _101f6af6 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _c5ad59cc = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _6af3775c = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _27dc8a4a = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _7c3df57c = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _3812e28a = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _46eb9468 = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _6eb9d0b6 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _1b70f144 = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _d4f46150 = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _447dacd2 = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _59ae6082 = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _072cec72 = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _1b362566 = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _15440d44 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _78910ae8 = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _0378cfad = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _5ccaa9be = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _35310b51 = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _0e205399 = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _3ea4982b = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _9a5987ce = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _5a4b1512 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _18980d14 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _147e05a2 = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _3aba3cf3 = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _b55c8d10 = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _428faba1 = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _610dea26 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _c85a7b00 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _396e7b44 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _c8610028 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _cd358468 = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _aceb8b96 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _bba69bfc = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _438286d9 = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _8deb9da4 = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _259351be = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _cf7ac3ce = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _70099328 = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _660cd495 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _abde983a = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _5146c197 = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _1a4f8d72 = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _7ed344c2 = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _120b359f = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _beb3460c = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _0b3ab334 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _28292813 = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _cc692722 = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _36a6e9d1 = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _dc8b0e20 = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _51c62782 = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _0b4259c4 = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _64e5aa58 = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _000cb4a4 = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _3a6ee144 = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _0b0f5336 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _1d869c24 = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _050d7674 = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _13532772 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _bd64ac8c = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _5bf0f080 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _59d5f794 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _19cd7372 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _75b9262c = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _32d32176 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _74956ce1 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _1445947d = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _2cf4f76d = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _2f1f3783 = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _502cfbf8 = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _e7e9d6ba = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _2e79b918 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _03207fe8 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _ee3701b4 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _05e02049 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _6e300103 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _59468d32 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _80f9da1c,
    name: "foo"
  }, {
    path: "/ie",
    component: _bececfe8,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _79c980ca,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _fd920a44,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _22fe6fb4,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _0c6d9ec4,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _7db6cbca,
    name: "appeals:frontend:show"
  }, {
    path: "/register",
    component: _01b280ec,
    name: "auth:register"
  }, {
    path: "/login",
    component: _0d462c80,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _7c6a6b9c,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _1b653e3c,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _44847200,
    children: [{
      path: "",
      component: _1090f3b5,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _330b9412,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _b62bb728,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _2a663b9a,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _55e07629,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _56a9c8d0
  }, {
    path: "/info/:uri*",
    component: _56a9c8d0,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _272ca790,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _4b962c95,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _adee9a2c,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _81cf3bee,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _19ef8ea5,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _507ab1f3,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _9f593a70,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _71b50259,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _51a23537,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _04903aa7,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _12eb2905,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _08839368,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _737e3643,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _509f840e,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _649e77bc,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _385760fc,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _5231a38b,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _384d94c0,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _52b91058,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _645ed3d0,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _0b36ab94,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _2bf37dc4,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _3222e022,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _101f6af6,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _c5ad59cc,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _6af3775c,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _27dc8a4a,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _7c3df57c,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _3812e28a,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _46eb9468,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _6eb9d0b6,
    name: "marketing:frontend:shop"
  }, {
    path: "/admin/mfis",
    component: _1b70f144,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _d4f46150,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _447dacd2,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _59ae6082,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _072cec72,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _1b362566,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _15440d44,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _78910ae8,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _0378cfad,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _5ccaa9be,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _35310b51,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _0e205399,
    children: [{
      path: "donations",
      component: _3ea4982b,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _9a5987ce,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _5a4b1512,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _18980d14,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _147e05a2,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _3aba3cf3,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _b55c8d10,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _428faba1,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _610dea26,
      children: [{
        path: "",
        component: _c85a7b00,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _396e7b44,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _c8610028,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _cd358468,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _aceb8b96,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _bba69bfc,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _438286d9,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _8deb9da4,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _259351be,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _cf7ac3ce,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _70099328,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _660cd495,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _abde983a,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _5146c197,
    children: [{
      path: "",
      component: _1a4f8d72,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _7ed344c2,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _120b359f,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _beb3460c,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _0b3ab334,
    children: [{
      path: "",
      component: _28292813,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _cc692722,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _36a6e9d1,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _dc8b0e20,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _51c62782,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _0b4259c4,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _64e5aa58,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _000cb4a4,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _3a6ee144,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _0b0f5336,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _1d869c24,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _050d7674,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _13532772,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _bd64ac8c,
    children: [{
      path: "",
      component: _5bf0f080,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _59d5f794,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _19cd7372,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _75b9262c,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _75b9262c,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _75b9262c,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _32d32176,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _74956ce1,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _1445947d,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _2cf4f76d,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _2f1f3783,
    children: [{
      path: "",
      component: _502cfbf8,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _e7e9d6ba,
      children: [{
        path: "",
        component: _2e79b918,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _03207fe8,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _ee3701b4,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _05e02049,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _6e300103,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _59468d32,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
