/**
 * @const {string}
 */
export const SET_CURRENT = 'SET_CURRENT'

/**
 * @typedef {Mutations} MFIs~Store~MutationsTypes
 * @property {function} SET_CURRENT
 */
/**
 * @type {MFIs~Store~MutationsTypes}
 */
export default {
  SET_CURRENT,
}
