import types from './mutation-types'

export default {
  /**
   * Set current MFI.
   * @param {MFI~State} state
   * @param {(MFI|null)} current
   */
  [types.SET_CURRENT] (state, current) {
    state.current = current
  },
  /**
   * Set current MFI.
   * @param {MFI~State} state
   * @param {(MFI|null)} current
   */
  SET_MFI (state, current) {
    state.current = current
  },
}
