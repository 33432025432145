/**
 * The prefix to images' public IDs.
 * @const {string}
 */
export const imagePublicIdPrefix = 'lwc/maps/'

export const apiRoutes = {
  admin: {
    profileTemplateOptions: '/admin/mfis/<%= id %>/profile-templates/<%= type %>/options',
    profileTemplate: '/admin/mfis/<%= id %>/profile-templates/<%= tplId %>',
  },
}
